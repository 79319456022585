// Import Splide.js library
import { Splide } from '@splidejs/splide';

// Splide web component
class ContentSlider extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.initSplide();
    this.initThemeEditor();
  }

  initSplide() {
    const isThemeEditor = window.Shopify && window.Shopify.designMode;

      this.slider = new Splide(this, {
        type: 'fade',
        perPage: 1,
        rewind: false,
        arrows: true,
        pagination: false,
        drag: true,
        arrowPath: 'm2.2742.9395 20 19.9999-20 20.0001-2.1149-2.115 17.8851-17.8851-17.8851-17.885 2.1149-2.1149z',
        classes: {
          arrow : 'splide__arrow cs-arrow',
        },
      }).mount(); 
  }

    // Display the correct slide when editing a slide block in the Theme Editor 
    initThemeEditor() {
      if (window.Shopify && window.Shopify.designMode) {
        document.addEventListener('shopify:block:select', (event) => this.onSectionSelect(event));
        document.addEventListener('shopify:block:select', (event) => this.onSectionDeselect(event));
      }
    }

    onSectionSelect(event) {
      const blockId = event.detail.blockId;

      if (blockId) {
        const slideIndex = [...this.children].findIndex((slide) => slide.dataset.blockId === blockId);
        console.log( event.detail );

        if (slideIndex >= 0) {
          this.slider.go(slideIndex);
        }
      }
    }

    onSectionDeselect() {
      this.slider.go(0); // Reset the slider to the first slide when a block is deselected
    }

  }

customElements.define('content-slider', ContentSlider);
